import { graphql } from 'gatsby';
import React from 'react';
import { styled } from '../styles/styled';
import { theme } from '../styles/theme';
import { Container } from './Container';
import { Divider } from './Divider';

export type SrcMap = { [K in keyof Partial<typeof theme.bp>]: string };

const getSortSrcMapKeys = (srcMap: SrcMap) => {
  return [
    srcMap.sm && 'sm',
    srcMap.md && 'md',
    srcMap.lg && 'lg',
    srcMap.xl && 'xl',
  ].filter(Boolean) as [keyof typeof theme.bp];
};

interface Props {
  imageSrc?: string | SrcMap;
  full?: boolean;
  title: string;
  subtitle?: string;
  action?: React.ReactNode;
}

const createBackgroundImage = (src: string) => {
  const gradiant = `linear-gradient(
    rgba(36, 41, 67, 0.9),
    rgba(36, 41, 67, 0.4),
    rgba(36, 41, 67, 0.6)
  )`;
  if (src) {
    return `background-image: ${gradiant}, url(${src});`;
  }
  return `background-image: ${gradiant};`;
};

const Root = styled<Pick<Props, 'full' | 'imageSrc'>, 'section'>('section')`
  padding-top: 80px;
  height: ${(props) => (props.imageSrc ? '500px' : '300px')};
  min-height: ${(props) => (props.imageSrc ? '500px' : '300px')};
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-color: rgba(36, 41, 67, 0.9);

  @media (min-width: ${(props) => props.theme.bp.md}) {
    padding-top: ${(props) => (props.full ? '0' : '80px')};
    height: ${(props) =>
      props.full ? '100vh' : props.imageSrc ? '500px' : '300px'};
    font-size: 2.5rem;
    text-align: left;
  }

  ${(props) => {
    if (!props.imageSrc) {
      return '';
    }
    if (typeof props.imageSrc === 'string') {
      return createBackgroundImage(props.imageSrc);
    }
    const [initial, ...sortedKeys] = getSortSrcMapKeys(props.imageSrc);
    const test = sortedKeys.reduce((prev, bp) => {
      const src: string = (props.imageSrc as any)[bp];
      const bpValue = props.theme.bp[bp];
      return `
      ${prev}
      @media(min-width: ${bpValue}) {
        ${createBackgroundImage(src)};
      }`;
    }, `${createBackgroundImage((props.imageSrc as any)[initial])}`);
    return test;
  }};
`;

const Title = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  @media (min-width: ${(props) => props.theme.bp.md}) {
    font-size: 2.5rem;
    text-align: center;
  }
`;

const SubTitle = styled.h2`
  color: #fff;
  font-size: 1rem;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 200;
  text-align: center;
  @media (min-width: ${(props) => props.theme.bp.md}) {
    font-size: 1.5rem;
  }
`;

const Hero: React.SFC<Props> = ({
  action,
  title,
  full,
  imageSrc,
  subtitle,
}) => (
  <Root full={full} imageSrc={imageSrc}>
    <Container>
      <Title>{title}</Title>
      {Boolean(subtitle) && (
        <>
          <Divider align="center" variant="light" />
          <SubTitle>{subtitle}</SubTitle>
        </>
      )}
      {Boolean(action) && action}
    </Container>
  </Root>
);

export interface HeroImageFragment {
  description: string;
  sm: { src: string };
  md: { src: string };
  lg: { src: string };
  xl: { src: string };
}

export const query = graphql`
  fragment HeroImage on ContentfulAsset {
    description
    sm: fixed(width: 768) {
      src
    }
    md: fixed(width: 992) {
      src
    }
    lg: fixed(width: 1100) {
      src
    }
    xl: fixed(width: 2500) {
      src
    }
  }
`;

export { Hero };
