import { Link } from 'gatsby';
import React from 'react';
import { urls } from '../constants';
import { styled } from '../styles/styled';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const Root = styled<Pick<Props, 'isOpen'>, 'aside'>('aside')`
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 400px;
  max-width: 80vw;
  transition: transform ease 200ms;
  background-color: ${(props) => props.theme.mainLight};
  transform: translate(${(props) => (props.isOpen ? '0' : '100%')}, 0);
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavListItem = styled.li`
  margin: 0;
`;

const NavLink = styled(Link)`
  display: block;
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(6)};
  color: #fff;
  opacity: 0.84;
  text-decoration: none;
  &:hover {
    opacity: 1;
    background-color: ${(props) => props.theme.main};
    text-decoration: none;
    color: #fff;
  }
`;

class SideNav extends React.Component<Props> {
  private rootRef = React.createRef<HTMLDivElement>();

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      document.addEventListener('click', this.handleDocumentClick, true);
    } else if (!this.props.isOpen && prevProps.isOpen) {
      document.removeEventListener('click', this.handleDocumentClick, true);
    }
  }

  public componentDidMount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  private handleDocumentClick = (event: MouseEvent) => {
    if (!this.rootRef.current) {
      return;
    }
    const isInsideClick = this.rootRef.current.contains(event.target as any);
    if (!isInsideClick) {
      event.preventDefault();
      event.stopPropagation();
      this.props.onClose();
    }
  };

  public render() {
    const { isOpen } = this.props;

    return (
      <Root isOpen={isOpen} ref={this.rootRef as any}>
        <nav>
          <NavList>
            <NavListItem>
              <NavLink to={urls.home}>Home</NavLink>
            </NavListItem>
            <NavListItem>
              <NavLink to={urls.about}>About</NavLink>
            </NavListItem>
            <NavListItem>
              <NavLink to={urls.riskAssessments.home}>
                Clinical Risk Assessments
              </NavLink>
            </NavListItem>
            {/* <NavListItem>
              <NavLink to={urls.resources.home}>Resources</NavLink>
            </NavListItem> */}
            <NavListItem>
              <NavLink to={urls.training}>Training</NavLink>
            </NavListItem>
            <NavListItem>
              <NavLink to={urls.contactUs}>Contact Us</NavLink>
            </NavListItem>
          </NavList>
        </nav>
      </Root>
    );
  }
}

export { SideNav };
