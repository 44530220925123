import { styled } from '../styles/styled';

interface Props {
  variant: 'primary' | 'light' | 'accent';
  align?: 'center' | 'left' | 'right';
}

const Divider = styled<Props, 'hr'>('hr')`
  width: 100px;
  background-color: ${props => {
    switch (props.variant) {
      case 'light':
        return '#fff';
      case 'accent':
        return props.theme.accent;
      case 'primary':
        return props.theme.main;
    }
  }};
  height: 3px;
  ${props => {
    switch (props.align) {
      case 'right':
        return `margin: ${props.theme.spacing(2)} 0 ${props.theme.spacing(
          2
        )} auto`;
      case 'left':
        return `margin: ${props.theme.spacing(2)} auto ${props.theme.spacing(
          2
        )} 0`;
      case 'center':
      default:
        return `margin: ${props.theme.spacing(2)} auto`;
    }
  }};
`;

Divider.defaultProps = {
  align: 'center',
};

export { Divider };
