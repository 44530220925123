import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { styled } from '../styles/styled';

const Split = styled<{ columns: string }, 'div'>('div')`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${props => props.theme.bp.md}) {
    grid-gap: ${props => props.theme.spacing(2)};
    grid-template-columns: 1fr 1fr;
  }
`;

const Footer = styled.div`
  text-align: center;
`;
const ContactForm = () => (
  <div>
    <form name="contact" method="post" action="?no-cache=1">
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        required
        label="Name"
        id="name"
        name="name"
        autoComplete="name"
      />
      <Split columns="1fr 1fr">
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          required
          placeholder="example@example.com"
          label="Email"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          placeholder="1-555-555-5555"
          label="Phone Number"
          id="phone"
          name="phone"
          autoComplete="tel"
        />
      </Split>
      <TextField
        fullWidth
        multiline
        rows={3}
        margin="normal"
        variant="outlined"
        required
        label="Message"
        id="message"
        name="message"
      />
      <Footer>
        <Button variant="contained" color="primary" type="submit">
          Send Message
        </Button>
      </Footer>
    </form>
  </div>
);

export { ContactForm };
