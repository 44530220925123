import React from 'react';
import { styled } from '../styles/styled';
import { Banner } from './Banner';

interface Mark {
  type: 'italic' | 'bold';
}

type RichTextNodeType =
  | 'document'
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'heading-6'
  | 'paragraph'
  | 'unordered-list'
  | 'list-item'
  | 'blockquote';

interface RichTextNode {
  data: Record<string, any>;
  content: Array<RichTextNode | RichTextTextNode>;
  nodeType: RichTextNodeType;
}

interface RichTextTextNode {
  marks: Mark[];
  value: React.ReactText;
  nodeType: 'text';
}

function renderContent(node: RichTextNode) {
  return node.content.map((contentNode, i) => (
    <ContentfulRichText key={i} node={contentNode} />
  ));
}

const TextNode = styled.span<{ marks: Mark[] }>`
  ${props =>
    props.marks.reduce((prev, mark) => {
      switch (mark.type) {
        case 'bold':
          return prev + 'font-weight: bold;';
        case 'italic':
          return prev + 'font-style: italic;';
        default:
          return prev;
      }
    }, '')}
`;

const defaultComponents: Record<RichTextNodeType, React.ReactType> = {
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'heading-5': 'h5',
  'heading-6': 'h6',
  'list-item': 'li',
  'unordered-list': 'ul',
  blockquote: Banner,
  document: 'div',
  paragraph: 'p',
};

interface ContentfulRichTextProps {
  node: RichTextNode | RichTextTextNode;
  components?: Partial<Record<RichTextNodeType, React.ReactType>>;
}

export const ContentfulRichText: React.SFC<ContentfulRichTextProps> = ({
  node,
  components,
}) => {
  if (node.nodeType === 'text') {
    return <TextNode marks={node.marks}>{node.value}</TextNode>;
  }
  const componentMap = {
    ...defaultComponents,
    ...components,
  };

  const NodeComponent = componentMap[node.nodeType];

  return NodeComponent ? (
    <NodeComponent>
      {node.content.map((contentNode, i) => (
        <ContentfulRichText
          key={i}
          node={contentNode}
          components={components}
        />
      ))}
    </NodeComponent>
  ) : null;
  // switch (node.nodeType) {
  //   case 'text':
  //     return <TextNode marks={node.marks}>{node.value}</TextNode>;
  //   case 'heading-1':
  //   case 'heading-2':
  //   case 'heading-3':
  //   case 'heading-4':
  //   case 'heading-5':
  //   case 'heading-6':
  //     const headingLevel = node.nodeType[node.nodeType.length - 1];
  //     const HeadingComponent = `h${headingLevel}`;
  //     return <HeadingComponent>{renderContent(node)}</HeadingComponent>;
  //   case 'unordered-list':
  //     return <ul>{renderContent(node)}</ul>;
  //   case 'list-item':
  //     return <li>{renderContent(node)}</li>;
  //   case 'blockquote':
  //     return <Banner>{renderContent(node)}</Banner>;
  //   case 'paragraph':
  //     return <p>{renderContent(node)}</p>;
  //   case 'document':
  //     return <div>{renderContent(node)}</div>;
  //   default:
  //     return null;
  // }
};
