import Paper from '@material-ui/core/Paper';
import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { ChevronDown, Menu } from 'react-feather';
import { urls } from '../constants';
import { styled } from '../styles/styled';
import { Container } from './Container';
import { DropdownMenu, DropdownMenuList } from './DropdownMenu';
import { DropdownMenuItem } from './DropdownMenuItem';
import { SideNav } from './SideNav';

const Content = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div``;

const Logo = styled(Link)`
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  &:hover {
    text-decoration: none;
  }
`;

const NavBar = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  display: none;
  @media (min-width: ${(props) => props.theme.bp.lg}) {
    display: flex;
  }
`;

const NavItem = styled.li`
  margin-left: ${(props) => props.theme.spacing(4)};
  margin-bottom: 0;
  position: relative;
`;

const NavLink = styled(Link)`
  color: #fff;
  opacity: 0.7;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  display: block;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    margin-top: 8px;
    left: 0;
    right: 0;
    height: 2px;
    transform: scale(0);
    transition: all ease 200ms;
    background-color: ${(props) => props.theme.accent};
  }
  &:hover,
  &.link-active {
    opacity: 1;
    &::after {
      transform: scale(1);
    }
    text-decoration: none;
  }
`;

const NavDropdownMenu = styled(DropdownMenu)`
  ${NavItem}:hover & {
    opacity: 1;
    visibility: visible;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
`;

NavLink.defaultProps = {
  activeClassName: 'link-active',
};

const Hamburger = styled.button`
  display: block;
  background-color: transparent;
  border: 0;
  height: 45px;
  width: 45px;
  line-height: 55px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @media (min-width: ${(props) => props.theme.bp.lg}) {
    display: none;
  }
`;

const Root = styled<{ fixed: boolean }, 'header'>('header')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${(props) =>
    props.fixed ? props.theme.main : 'transparent'};
  padding-right: ${(props) => props.theme.spacing(2)};
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-top: ${(props) => props.theme.spacing(props.fixed ? 3 : 6)};
  padding-bottom: ${(props) => props.theme.spacing(props.fixed ? 2 : 6)};
  box-shadow: ${(props) =>
    props.fixed
      ? '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
      : ''};
  transition: all ease 200ms;
  & ${NavLink} {
    &::after {
      margin-top: ${(props) => (props.fixed ? '20px' : '8px')};
    }
  }
`;

interface State {
  fixed: boolean;
  navOpen: boolean;
}

interface GQLData {
  clinicalRiskAssessments: {
    services: Array<{
      id: string;
      shortTitle: string;
      slug: string;
    }>;
  };
}

class Header extends React.Component<{}, State> {
  public state: State = {
    fixed: false,
    navOpen: false,
  };

  public componentDidMount() {
    if (window.location.search) {
      window.history.replaceState(
        {},
        '',
        `${window.location.origin}${window.location.pathname}`
      );
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public handleScroll = () => {
    const { fixed } = this.state;
    const scrollPos = window.scrollY;
    if (fixed && scrollPos <= 75) {
      this.setState({ fixed: false });
    } else if (!fixed && scrollPos > 75) {
      this.setState({ fixed: true });
    }
  };

  public handleNavToggle = () => {
    this.setState({ navOpen: !this.state.navOpen });
  };

  public render() {
    const { fixed, navOpen } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query HeaderData {
            clinicalRiskAssessments: contentfulService(
              slug: { eq: "/clinical-risk-assessments" }
            ) {
              services {
                id
                shortTitle
                slug
              }
            }
          }
        `}
        render={({ clinicalRiskAssessments }: GQLData) => (
          <Root fixed={fixed}>
            <Content>
              <LogoWrapper>
                <Logo to={urls.home}>PHARMA SAFETY SOLUTIONS</Logo>
              </LogoWrapper>

              <NavBar>
                <NavItem>
                  <NavLink to={urls.home}>Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={urls.about}>About</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={urls.riskAssessments.home}>
                    Clinical Risk Assessments <ChevronDown size={14} />
                  </NavLink>
                  <NavDropdownMenu style={{ left: -35 }}>
                    <DropdownMenuList>
                      {clinicalRiskAssessments.services.map((assessment) => (
                        <DropdownMenuItem
                          key={assessment.id}
                          to={assessment.slug}
                        >
                          {assessment.shortTitle}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuList>
                  </NavDropdownMenu>
                </NavItem>
                {/* <NavItem>
                  <NavLink to={urls.resources.home}>Resources</NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink to={urls.training}>Training</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={urls.contactUs}>Contact Us</NavLink>
                </NavItem>
              </NavBar>
              <Hamburger
                onClick={this.handleNavToggle}
                title="Toggle Navigation"
              >
                <Menu color="#fff" size={24} />
              </Hamburger>
            </Content>
            <SideNav isOpen={navOpen} onClose={this.handleNavToggle} />
          </Root>
        )}
      />
    );
  }
}

/* 
export const query = graphql`
  query ClinicalRiskAssessmentsService {
    service: contentfulService(slug: { eq: "/clinical-risk-assessments" }) {
      services {
        id
        shortTitle
        slug
      }
    }
  }
`;
*/

export { Header };
