import styled from 'styled-components';

export const DropdownMenu = styled.div`
  background: #fff;
  width: 400px;
  padding: 29px 26px 18px;
  position: absolute;
  top: 100%;
  left: -96px;
  margin-top: 25px;
  -ms-transform: translateY(17px);
  transform: translateY(17px);
  opacity: 0;
  visibility: hidden;
  display: block !important;
  will-change: transform, opacity;
  box-shadow: 0 12px 20px 0 rgba(23, 28, 33, 0.16);
  border-radius: 5px;
  transition: transform 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: 100%;
    left: 124px;
    height: 0;
    width: 0;
    border-bottom: 7px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
  }
  &::after {
    content: '';
    height: 36px;
    left: 0;
    right: 0;
    bottom: 100%;
    position: absolute;
  }
`;

export const DropdownMenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
