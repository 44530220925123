export const urls = {
  home: '/',
  about: '/about/',
  contactUs: '/contact-us/',
  training: '/training/',
  resources: {
    home: '/resources/',
  },
  riskAssessments: {
    home: '/clinical-risk-assessments/',
    biologicProduct: '/clinical-risk-assessments/biologic-product/',
    medicalDevice: '/clinical-risk-assessments/medical-device/',
    pharmaProduct: '/clinical-risk-assessments/pharma-product/',
  },
};
