import { ButtonBase } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Mail, Phone } from 'react-feather';
import { styled } from '../styles/styled';
import { muiTheme } from '../styles/theme';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.spacing(2)};
  margin-top: 32px;
  @media (min-width: ${props => props.theme.bp.md}) {
    margin-top: 0;
  }
`;

const ContactEntry = styled(ButtonBase)`
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.spacing(3)};
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  color: ${props => props.theme.main};

  &:hover {
    color: ${props => props.theme.mainLight};
    /* background-color: #ececec; */
    text-decoration: none;
    background-color: ${fade(
      muiTheme.palette.text.primary,
      muiTheme.palette.action.hoverOpacity
    )};
  }
  @media (min-width: ${props => props.theme.bp.sm}) {
    height: 125px;
    margin-bottom: 0;
    text-align: left;
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
  }
` as any;

const ContactEntryIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.theme.spacing(2)};
  justify-content: center;
  @media (min-width: ${props => props.theme.bp.sm}) {
    padding-bottom: 0;
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(4)};
  }
`;

const ContactEntryIcon = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.main};
  border-radius: 50%;
`;

const ContactEntryDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContactEntryTitle = styled.h4`
  margin: 0 0 ${props => props.theme.spacing(1)} 0;
`;
const ContactEntryValue = styled.div`
  margin: 0;
`;

interface GQLData {
  contactInfo: {
    name: string;
    email: string;
    phoneNumber: string;
  };
}

const ContactInfo = () => (
  <StaticQuery
    query={graphql`
      query ContactInfo {
        contactInfo: contentfulTeamMember {
          name
          email
          phoneNumber
        }
      }
    `}
    render={({ contactInfo }: GQLData) => (
      <Root>
        <ContactEntry href={`mailto:${contactInfo.email}`}>
          <ContactEntryIconWrapper>
            <ContactEntryIcon>
              <Mail size={24} />
            </ContactEntryIcon>
          </ContactEntryIconWrapper>
          <ContactEntryDetails>
            <ContactEntryTitle>Email</ContactEntryTitle>
            <ContactEntryValue>{contactInfo.email}</ContactEntryValue>
          </ContactEntryDetails>
        </ContactEntry>
        <ContactEntry href={`tel:+${contactInfo.phoneNumber}`}>
          <ContactEntryIconWrapper>
            <ContactEntryIcon>
              <Phone size={24} />
            </ContactEntryIcon>
          </ContactEntryIconWrapper>
          <ContactEntryDetails>
            <ContactEntryTitle>Phone</ContactEntryTitle>
            <ContactEntryValue>+{contactInfo.phoneNumber}</ContactEntryValue>
          </ContactEntryDetails>
        </ContactEntry>
      </Root>
    )}
  />
);

export { ContactInfo };
