import React from 'react';
import { styled } from '../styles/styled';
import { ContactForm } from './ContactForm';
import { ContactInfo } from './ContactInfo';

const Split = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${props => props.theme.bp.md}) {
    grid-gap: ${props => props.theme.spacing(2)};
    grid-template-columns: 1fr 1fr;
  }
`;

const ContactSection = () => (
  <Split>
    <ContactForm />
    <ContactInfo />
  </Split>
);

export { ContactSection };
