import { styled } from '../styles/styled';

export const Container = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.spacing(2)};
  margin: 0 auto;
  @media (min-width: ${props => props.theme.bp.sm}) {
    max-width: 540px;
  }
  @media (min-width: ${props => props.theme.bp.md}) {
    max-width: 720px;
  }
  @media (min-width: ${props => props.theme.bp.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${props => props.theme.bp.xl}) {
    max-width: 1200px;
  }
`;
