import { styled } from '../styles/styled';

const Banner = styled.div`
  margin: ${props => props.theme.spacing(4)} auto;
  max-width: 800px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  padding: ${props => props.theme.spacing(4)};
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  & p {
    margin: 0;
  }
`;

export { Banner };
