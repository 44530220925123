import blueGrey from '@material-ui/core/colors/blueGrey';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = {
  main: '#242943',
  mainLight: '#393e55',
  accent: '#ff5722',
  accentHover: '#ff8a50',
  bp: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1100px',
  },
  spacing(scale = 1) {
    return `${8 * scale}px`;
  },
};

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#242943',
    },
    secondary: {
      main: '#de3500',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 60,
        fontSize: 14,
        padding: '0.625em 1.6em',
      },
      contained: {
        backgroundColor: '#fff',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: deepOrange[500],
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: deepOrange[500],
        },
      },
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
});
