import { graphql, Link as GatsbyLink, StaticQuery } from 'gatsby';
import React from 'react';
import { Linkedin, Mail, Phone } from 'react-feather';
import { urls } from '../constants';
import { styled } from '../styles/styled';
import { Container } from './Container';

export const Root = styled.footer`
  padding: 100px 0 50px;
  color: #fff;
  background-color: ${(props) => props.theme.main};
`;

const Sections = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.spacing(2)};
  text-align: center;
  @media (min-width: ${(props) => props.theme.bp.md}) {
    text-align: left;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Logo = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
`;

const Tagline = styled.div`
  font-size: 14px;
  opacity: 0.84;
  margin: 0 auto;
  max-width: 250px;
  @media (min-width: ${(props) => props.theme.bp.md}) {
    margin: 0;
  }
`;

const Copyright = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 16px 0 0;
  text-align: left;
  @media (min-width: ${(props) => props.theme.bp.md}) {
    text-align: left;
  }
`;

const SectionHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinkItem = styled.li`
  margin: 0;
`;

const Link = styled(GatsbyLink)`
  color: #fff;
  opacity: 0.84;
  font-size: 14px;
  text-decoration: none;
  &:hover {
    color: #fff;
    opacity: 1;
    text-decoration: none;
  }
`;

const SocialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialLinks = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const SocialLinkItem = styled.li`
  margin: 0 ${(props) => props.theme.spacing(1)};
`;

const IconLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-decoration: none;
  opacity: 0.84;
  color: #fff;
  transition: all ease 200ms;

  &:hover {
    color: ${(props) => props.theme.main};
    background-color: #fff;
    opacity: 1;
  }
`;

interface GQLData {
  contactInfo: {
    name: string;
    email: string;
    phoneNumber: string;
    linkedIn: string;
  };
}

const year = new Date().getFullYear();

const Footer: React.SFC = () => (
  <StaticQuery
    query={graphql`
      query FooterData {
        contactInfo: contentfulTeamMember {
          name
          email
          phoneNumber
          linkedIn
        }
      }
    `}
    render={({ contactInfo }: GQLData) => (
      <Root>
        <Container>
          <Sections>
            <div>
              <Logo>Pharma Safety Solutions</Logo>
              <Tagline>
                Providing Clinical Benefit-Risk Profile Assessments of
                Pharmaceutical Product Quality Attributes
              </Tagline>
            </div>
            <div>
              <SectionHeader>Explore</SectionHeader>
              <LinkList>
                <LinkItem>
                  <Link to={urls.home}>Home</Link>
                </LinkItem>
                <LinkItem>
                  <Link to={urls.about}>About</Link>
                </LinkItem>

                <LinkItem>
                  <Link to={urls.riskAssessments.home}>
                    Clinical Risk Assessments
                  </Link>
                </LinkItem>
                <LinkItem>
                  <Link to={urls.riskAssessments.pharmaProduct}>
                    Pharmaceutical Product Risk Assessments
                  </Link>
                </LinkItem>
                <LinkItem>
                  <Link to={urls.riskAssessments.medicalDevice}>
                    Medical Device Risk Assessments
                  </Link>
                </LinkItem>
                <LinkItem>
                  <Link to={urls.riskAssessments.biologicProduct}>
                    Biologic Product Risk Assessments
                  </Link>
                </LinkItem>
                {/* <LinkItem>
                  <Link to={urls.resources.home}>Resources</Link>
                </LinkItem> */}
                <LinkItem>
                  <Link to={urls.training}>Training</Link>
                </LinkItem>
                <LinkItem>
                  <Link to={urls.contactUs}>Contact Us</Link>
                </LinkItem>
              </LinkList>
            </div>
            <SocialSection>
              <SectionHeader>Get In Touch</SectionHeader>
              <SocialLinks>
                <SocialLinkItem>
                  <IconLink href={`mailto:${contactInfo.email}`} title="Email">
                    <Mail size={16} />
                  </IconLink>
                </SocialLinkItem>
                <SocialLinkItem>
                  <IconLink
                    href={`tel:+${contactInfo.phoneNumber}`}
                    title="Phone"
                  >
                    <Phone size={16} />
                  </IconLink>
                </SocialLinkItem>
                <SocialLinkItem>
                  <IconLink
                    href={contactInfo.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="LinkedIn"
                  >
                    <Linkedin size={16} />
                  </IconLink>
                </SocialLinkItem>
              </SocialLinks>
            </SocialSection>
          </Sections>
          <Copyright>&#169; Pharma Safety Solutions, LLC {year}</Copyright>
        </Container>
      </Root>
    )}
  />
);

export { Footer };
