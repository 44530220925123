import { Link } from 'gatsby';
import React from 'react';
import { ArrowRight } from 'react-feather';
import { styled } from '../styles/styled';

const Root = styled.li`
  list-style: none;
  padding: none;
  margin: none;
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const LinkWrap = styled(Link)`
  text-decoration: none;
  color: unset;
`;

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
`;

const LearnMore = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.accent};

  ${Root}:hover & {
    color: ${props => props.theme.accentHover};
  }
`;

const Arrow = styled(ArrowRight)`
  margin-left: 5px;
  visibility: none;
  transition: transform 0.3s linear, opacity 0.3s linear, visibility 0.3s linear;
  transform: translateX(-10px);
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
  ${Root}:hover & {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
`;

export interface DropdownMenuItemProps {
  to: string;
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  to,
  children,
}) => {
  return (
    <Root>
      <LinkWrap to={to}>
        <Title>{children}</Title>
        <LearnMore>
          Learn More <Arrow size={12} />
        </LearnMore>
      </LinkWrap>
    </Root>
  );
};
