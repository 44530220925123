import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import React from 'react';
import Helmet from 'react-helmet';
import favicon from '../static/favicon.ico';
import '../styles/global.css';
import { ThemeProvider } from '../styles/styled';
import { muiTheme, theme } from '../styles/theme';
import { Footer } from './Footer';
import { Header } from './Header';

interface Props {
  title?: string;
}

const Layout: React.SFC<Props> = ({ title, children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={theme}>
      <>
        <Helmet
          title={`${title ? `${title} | ` : ''}Pharma Safety Solutions`}
          meta={[
            {
              name: 'description',
              content:
                'Pharma Safety Solutions delivers comprehensive pharmaceutical and device clinical risk assessment solutions.',
            },
            {
              name: 'keywords',
              content: 'Pharmaceutical,Clinical Risk Assessment,Training',
            },
          ]}
          link={[{ rel: 'shortcut icon', type: 'image/png', href: favicon }]}
        >
          <html lang="en" />
        </Helmet>
        <Header />
        <main>{children}</main>
        <Footer>footer</Footer>
      </>
    </ThemeProvider>
  </MuiThemeProvider>
);

Layout.defaultProps = {
  title: '',
};

export { Layout };
