import { graphql } from 'gatsby';
import React from 'react';
import { ContactSection } from '../../components/ContactSection';
import { Hero, HeroImageFragment, SrcMap } from '../../components/Hero';
import { Layout } from '../../components/Layout';
import { ContentfulRichText } from '../../components/RichText';
import { Section } from '../../components/Section';

interface Props {
  data: {
    service: {
      title: string;
      includeContactForm?: boolean;
      image: HeroImageFragment;
      content: {
        content: string;
      };
      contentTitle: {
        contentTitle: string;
      };
      contentSubtitle: {
        contentSubtitle: string;
      };
    };
  };
}

export default (props: Props) => {
  const { title, image, includeContactForm } = props.data.service;
  const imageMap: SrcMap = {
    sm: image.sm.src,
    md: image.md.src,
    lg: image.lg.src,
    xl: image.xl.src,
  };
  const parsedContent = props.data.service.content
    ? JSON.parse(props.data.service.content.content)
    : {};

  return (
    <Layout title={title}>
      <Hero imageSrc={imageMap} title={title} />
      <Section alt>
        <ContentfulRichText node={parsedContent} />
      </Section>
      {includeContactForm && (
        <Section
          title="Want to talk?"
          subTitle="Contact us today to discuss your specific needs and objectives"
        >
          <ContactSection />
        </Section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query BiopharmaceuticalProductRiskService {
    service: contentfulService(
      slug: { eq: "/clinical-risk-assessments/biologic-product" }
    ) {
      title
      includeContactForm
      image {
        ...HeroImage
      }
      content {
        content
      }
      contentTitle {
        contentTitle
      }
      contentSubtitle {
        contentSubtitle
      }
    }
  }
`;
