import React from 'react';
import { styled } from '../styles/styled';
import { Container } from './Container';
import { Divider } from './Divider';

interface Props {
  alt?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

const Root = styled<{ isAlt?: boolean }, 'section'>('section')`
  background-color: ${props => (props.isAlt ? '#efefef' : '#fff')};
  position: relative;
  padding: ${props => `${props.theme.spacing(10)} ${props.theme.spacing(2)}`};
  @media (min-width: ${props => props.theme.bp.lg}) {
    padding: ${props => props.theme.spacing(10)} 0;
  }
`;

const Header = styled.div`
  text-align: center;
  & p {
    margin: 0;
  }
`;

const Title = styled.h3`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const Subtitle = styled.h5`
  max-width: 500px;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
`;

const Section: React.SFC<Props> = ({ children, alt, title, subTitle }) => (
  <Root isAlt={alt}>
    <Container>
      {Boolean(title || subTitle) && (
        <Header>
          {Boolean(title) && <Title>{title}</Title>}
          {Boolean(title && subTitle) && (
            <Divider variant="accent" align="center" />
          )}
          {Boolean(subTitle) && <Subtitle>{subTitle}</Subtitle>}
        </Header>
      )}
      {children}
    </Container>
  </Root>
);

export { Section };
